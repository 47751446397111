import {Triangle} from "react-loader-spinner";
import React from "react";

const TriangleLoader = ({width, height}) => {
	return(
		<Triangle
			visible={true}
			height={height}
			width={width}
			color="#ff8800"
			ariaLabel="triangle-loading"
			wrapperStyle={{}}
			wrapperClass=""
		/>
	)
}

export default TriangleLoader;