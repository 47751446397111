import AddRating from "./AddRating";
import GetRatings from "./GetRatings";

const Ratings = ({singleData}) => {

	return(
		<div>
			<AddRating singleData={singleData} />
			<GetRatings ratings={singleData.rating} offerID={singleData._id} />
		</div>
	)
}

export default Ratings;