const getFormattedDate = (timestamp) => {

	if(!timestamp){
		return
	}

	const date = new Date(timestamp);

	let month = date.getMonth() + 1;
	let day = date.getDate();
	let hour = date.getHours();
	let min = date.getMinutes();
	let sec = date.getSeconds();

	month = (month < 10 ? "0" : "") + month;
	day = (day < 10 ? "0" : "") + day;
	hour = (hour < 10 ? "0" : "") + hour;
	min = (min < 10 ? "0" : "") + min;
	sec = (sec < 10 ? "0" : "") + sec;

	const str = date.getFullYear() + "-" + month + "-" + day + " " +  hour + ":" + min + ":" + sec;

	return str;
}

export default getFormattedDate