import {Formik} from "formik";
import ValidationError from "../../../parts/validation-error/ValidationError";
import Stars from "./Stars";
import styles from './add-rating.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {
	averageRating as reduxAverageRating,
	totalReviews as reduxTotalReviews,
	fetchRatingCreate
} from "../../../../redux/slices/ratingSlice";
import {usePopupContext} from "../../../parts/contexts/PopupContext";
import CloseButton from "../../../parts/close-button/CloseButton";
import CloseOutside from "../../../parts/close-outside/CloseOutside";
import RatingStars from "../../../parts/rating-starts/RatingStars";
import {user as reduxUser} from "../../../../redux/slices/userSlice";
import Auth from "../../../auth/Auth";

const AddRatingPopup = ({singleData}) => {
	const dispatch = useDispatch()
	const { hidePopup } = usePopupContext();
	const initialData = {
		rate: 0,
		text: ''
	}

	const handleSubmit = async (values) => {

		await dispatch(fetchRatingCreate({
			offerID: singleData._id,
			text: values.text,
			rate: values.rate
		}))
	}

	return(
		<CloseOutside handleClose={() => hidePopup()}>
			<div className={styles.add_rating_popup}>
				<div className={styles.close_button_wrapper}>
					<CloseButton handleClose={() => hidePopup()} />
				</div>
				<Formik initialValues={initialData} onSubmit={handleSubmit}>
					{({
						  values,
						  errors,
						  handleSubmit,
						  handleChange,
						  handleBlur,
					  }) => (
						<form onSubmit={handleSubmit}>
							<h3 className={styles.add_rating_title}>Dodaj opinie</h3>
							<Stars />
							<>
								<div className="input-wrapper primary">
									<textarea className={`input-typing ${values.text.length === 0 ? '' : 'has-content'} ${values.text.length === 0 ? '' : (errors.text ? 'no-valid' : 'valid')} ${styles.text_area_input}`} type="text"  onChange={handleChange} onBlur={handleBlur} name="text" value={values.text} />
									<label>Treść opinii</label>
									<span className="focus-border"></span>
									<ValidationError isErrorVisible={errors.text} errorMessage={errors.text} />
								</div>
								<button className={`btn-primary ${styles.add_rating_submit}`}>
									Dodaj opinie
								</button>
							</>

						</form>
					)}
				</Formik>
			</div>
		</CloseOutside>
	)
}

const AddRating = ({singleData}) => {
	const { showPopup } = usePopupContext();
	const averageRating = useSelector(reduxAverageRating)
	const totalReviews = useSelector(reduxTotalReviews);
	const user = useSelector(reduxUser)
	const addRatingPopup = () => {
		if(user.type === 'quest'){
			return showPopup(Auth, {type: 'register'})
		}
		showPopup(AddRatingPopup, {singleData: singleData})
	}

	return(
		<div className={styles.add_rating}>
			<div className={styles.add_rating_info}>
				<span className={styles.average_rating}>{averageRating.toFixed(2)}/5</span>
				<RatingStars averageRating={averageRating} size={32} />
				<p>{totalReviews} opinie</p>
			</div>
			<button onClick={addRatingPopup} className={`btn-primary ${styles.add_rating_button}`}>Dodaj Opinie</button>
		</div>
	)
}

export default AddRating;