const bottomFooterConfig = [
    {
        name: 'Kategorie',
        data: [
            {
                link: '',
                text: 'Zabawki'
            },
            {
                link: '',
                text: 'Pojazdy'
            },
            {
                link: '',
                text: 'Dla najmłodszych'
            },
            {
                link: '',
                text: 'Dekoracje'
            },
            {
                link: '',
                text: 'Wodne'
            }
        ]
    }
]



export default bottomFooterConfig;