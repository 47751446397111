import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import callToAPI from "../../api";

export const fetchRatingCreate = createAsyncThunk('rating/fetchRatingCreate', async (ratingData) => {
	return await callToAPI('/rating/create', 'post', {
		...ratingData
	})
})

export const fetchRatingsGet = createAsyncThunk('rating/fetchRatingsGet', async ({offerID, page}) => {
	return await callToAPI(`/ratings/get?page=${page}&status=active`, 'post', {
		offerID: offerID
	})
})


const initialState = {
	limit: 0,
	page: 0,
	pages: 0,
	ratings: [],
	total: 0,
	ratingsStatus: '',
	totalReviews: 0,
	averageRating: 0
}

const slice = createSlice({
	name: 'rating',
	initialState,
	reducers: {
		setCurrentPage: (state, action) => {
			state.page = action.payload
		}
	},
	extraReducers: (builder) => {
		builder.addCase(fetchRatingsGet.fulfilled, (state, {payload}) => {
			delete payload.page
			state.ratings = payload.ratings
			state.limit = payload.limit
			state.pages = payload.pages - 1
			state.total = payload.total
			state.totalReviews = payload.totalReviews
			state.averageRating = payload.averageRating
			state.ratingsStatus = 'fulfilled'
		})
		builder.addCase(fetchRatingsGet.pending, (state, {payload}) => {
			state.ratingsStatus = 'pending'
		})
		builder.addCase(fetchRatingsGet.rejected, (state) => {
			state.ratingsStatus = 'rejected'
		})
	}
})

export const {setCurrentPage} = slice.actions


export const ratingsStatus = state => state.rating.ratingsStatus

export const ratings = state => state.rating.ratings

export const page = state => state.rating.page
export const limit = state => state.rating.limit
export const pages = state => state.rating.pages
export const total = state => state.rating.total
export const averageRating = state => state.rating.averageRating
export const totalReviews = state => state.rating.totalReviews

export default slice.reducer