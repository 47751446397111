import styles from './faq.module.scss'
const FAQ = () => {
    return(
        <div className="container">
            <div className={styles.faq}>
                <div className={styles.faq_title_wrapper}>
                    <h2 className={styles.faq_title}>FAQ - pytania</h2>
                </div>
                dsad
            </div>
        </div>
    )
}

export default FAQ;