import React from "react";
import { IoMdStarHalf, IoMdStar, IoMdStarOutline } from "react-icons/io";
import styles from './rating-stars.module.scss'
const RatingStars = ({ averageRating, size }) => {
	const fullStars = Math.floor(averageRating);
	const hasHalfStar = averageRating - fullStars >= 0.25 && averageRating - fullStars < 0.75;

	const stars = Array.from({ length: 5 }, (_, index) => {
		if (index < fullStars) {
			return <IoMdStar style={{fontSize: `${size}px`}} key={index} className={styles.gold_star} />;
		} else if (index === fullStars && hasHalfStar) {
			return <IoMdStarHalf style={{fontSize: `${size}px`}} key={index} className={styles.half_gold_star} />;
		} else {
			return <IoMdStarOutline style={{fontSize: `${size}px`}} key={index} className={styles.grey_star} />;
		}
	});

	return (
		<div className={styles.rating_stars_container}>
			{stars.map(star => star)}
		</div>
	);
};

export default RatingStars;