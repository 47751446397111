import {useEffect, useState} from "react";
import callToAPI from "../../api";

const Summary = () => {
	const orderID = localStorage.getItem('order-id');
	const [paymentData, setPaymentData] = useState();
	const [paymentUrl, setPaymentUrl] = useState()
	useEffect(() => {
		console.log(orderID)
		callToAPI(`/order/get/${orderID}`, 'get')
			.then(res => setPaymentData({
				price: res.basket.price,
				orderID: res._id,
			}));
	}, [orderID]);
	useEffect(() => {
		if(paymentData){
			callToAPI('/payment/get', 'post', {
				price: paymentData.price,
				orderID: paymentData.orderID,
				serviceName: `Przedmiot/Przedmioty ze strony zabawix.com, nr zamówienia ${paymentData.orderID}`
			})
				.then(res => {
					setPaymentUrl(res.paymentUrl)
				});
		}
	}, [paymentData])
	return(
		<div className="container">
			<h1>Podsumowanie</h1>
			<a href={paymentUrl} className='btn-primary'>
				Zapłać
			</a>
		</div>
	)
}

export default Summary;