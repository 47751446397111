import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import callToAPI from "../../../api";

const OrderStatus = () => {
	const id = useParams().id;
	const [paymentData, setPaymentData] = useState({});
	useEffect(() => {
		if(id){
			callToAPI(`/payment/get/${id}`, 'get')
				.then(res => setPaymentData(res));
		}
	}, [id]);
	return(
		<div className="container">
			{paymentData.status}
		</div>
	)
}

export default OrderStatus;