import React, { useState } from 'react';
import styles from './stars.module.scss';
import {useFormikContext} from "formik";
import {useSelector} from "react-redux";
import {user as reduxUser} from "../../../../redux/slices/userSlice";
import Auth from "../../../auth/Auth";
import {usePopupContext} from "../../../parts/contexts/PopupContext";
import { IoMdStar } from "react-icons/io";
const Stars = () => {
	const [rating, setRating] = useState(0);
	const {setFieldValue, values} = useFormikContext()
	const user = useSelector(reduxUser)
	const {showPopup} = usePopupContext()
	const handleRating = (rate) => {
		if(user?.type === 'quest'){
			return showPopup(Auth, {type: 'register'})
		}
		setRating(rate);
		setFieldValue('rate', rate)
	};

	return (
		<div className={styles.wrapper}>
			{[1, 2, 3, 4, 5].map((star) => (
				<label key={star}>
					<input
						type="radio"
						name="rating"
						value={values.rate}
						onClick={() => handleRating(star)}
					/>
					<IoMdStar
						className={`${styles.star} ${rating >= star ? styles.active : ''}`}
						size={30}
					/>
				</label>
			))}
		</div>
	);
};

export default Stars;