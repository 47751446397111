import React, {useEffect, useRef} from "react";
import {IoMdStar} from 'react-icons/io'
import styles from './get-ratings.module.scss'
import getFormattedDate from "../../../../helpers/date/getFormattedDate";
import {usePopupContext} from "../../../parts/contexts/PopupContext";
import {
	fetchRatingsGet,
	ratings as reduxRatings,
	ratingsStatus as reduxRatingsStatus,
	pages as reduxPages,
	page as reduxPage,
	setCurrentPage
} from "../../../../redux/slices/ratingSlice";
import {useDispatch, useSelector} from "react-redux";
import Pagination from "../../../parts/pagination/Pagination";
import {Blocks, MutatingDots, Triangle} from "react-loader-spinner";
import TriangleLoader from "../../../parts/triangel-loader/TriangleLoader";
const GetRatings = ({offerID}) => {

	const dispatch = useDispatch()
	const ratingsRef = useRef()
	const ratings = useSelector(reduxRatings)
	const pages = useSelector(reduxPages)
	const page = useSelector(reduxPage)
	const ratingsStatus = useSelector(reduxRatingsStatus)

	useEffect(() => {
		dispatch(fetchRatingsGet({offerID, page: page}))
	}, [page]);

	const onChangePage = (page) => {
		dispatch(setCurrentPage(page))
	}

	return(
		<>
			{ratingsStatus === 'pending' && <TriangleLoader width='100%' height={100} />}
			{ratingsStatus === 'fulfilled' &&
				<div className={styles.get_ratings}>
					<ul className={styles.ratings_list} ref={ratingsRef}>
						{ratings.map((rating, index) => (
							<li key={index} className={styles.ratings_item}>
								<p className={styles.created_at}>
									Dodano: {getFormattedDate(rating.createdAt)}
								</p>
								<div className={styles.top_content}>
									<p className={styles.ratings_username}>{rating.username}</p>
									<div>
										{[1, 2, 3, 4, 5].map(el => (
											<IoMdStar
												className={`${styles.star} ${rating.rate >= el ? styles.active : ''}`}
												size={30}
											/>
										))}
									</div>
								</div>
								<p className={styles.main_text}>
									{rating.text}
								</p>
							</li>
						))}
					</ul>
					<Pagination scrollRef={ratingsRef} numberOfPages={pages} currentPage={page} onChangePage={onChangePage} />
				</div>
			}
		</>
	)
}

export default GetRatings;