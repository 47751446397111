const parametersConfig = {
	sex: [
		{
			slug: 'male',
			title: 'Chłopiec'
		},
		{
			slug: 'female',
			title: 'Dziewczynka'
		},
		{
			slug: 'unisex',
			title: 'Uniseks'
		}
	],
	brand: [
		{
			slug: 'lean',
			title: 'LEAN Toys'
		},
		{
			slug: 'bestway',
			title: 'Bestway'
		}
	]
}

export default parametersConfig